import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { track } from '@/utils/track';
import { request } from '@/utils';
import { useABTestValue } from '@/store';
import styles from './TopBanner.module.scss';

export const pathsWithNoBanner = [
  '/user',
  '/register',
  '/activity',
  '/console',
  '/experience',
  '/market/chager',
];
const labelTextListA = [
  '专为LLM下游任务设计的通用文档解析服务',
  '一站式解析文字、表格、公式等内容',
];
const labelTextListB = [
  '支持双栏、三栏版式识别，正确还原阅读顺序',
  '精准解析合并单元格、无线表，一键生成表格数据',
];

const hasShownTopBannerCacheKey = 'hasShownTopBannerCacheKey';

const Banner = () => {
  const timerRef = useRef<NodeJS.Timeout>();
  const isTestB = useABTestValue() === 'B';
  const labelTextList = isTestB ? labelTextListB : labelTextListA;
  const [labelText, setLabelText] = useState(labelTextList[0]);
  const [show, setShow] = useState(false);

  const bannerVersion = `test ${isTestB ? 'B' : 'A'}`;

  const isOwned = async () => {
    try {
      const res = await request.get(`/robot/detail`, { params: { service: 'pdf_to_markdown' } });
      if (res?.data?.is_owned) {
        return true;
      }
      return false;
    } catch (error) {
      //
      return false;
    }
  };

  useEffect(() => {
    isOwned().then((owned) => {
      if (!owned) {
        const hasShownTopBanner = localStorage.getItem(hasShownTopBannerCacheKey) === '1';
        setShow(!hasShownTopBanner);
      }
    });
  }, []);

  useEffect(() => {
    if (show) {
      timerRef.current = setInterval(() => {
        setLabelText(labelTextList[Math.floor(Math.random() * labelTextList.length)]);
      }, 2 * 1000);
      track({
        keyword: '导航栏【顶部banner】（曝光）',
        url: location.href,
        banner_version: bannerVersion,
      });
    }
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [show]);

  const handleClickBanner = () => {
    window.open('/market/detail/pdf_to_markdown', '_blank');
    setShow(false);
    localStorage.setItem(hasShownTopBannerCacheKey, '1');
    track({
      keyword: '导航栏【顶部banner】',
      url: location.href,
      button_click: '产品页',
      banner_version: bannerVersion,
    });
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShow(false);
    localStorage.setItem(hasShownTopBannerCacheKey, '1');
    track({
      keyword: '导航栏【顶部banner】',
      url: location.href,
      button_click: '关闭',
      banner_version: bannerVersion,
    });
  };

  if (!show) {
    return null;
  }

  return (
    <div className={styles.container} onClick={handleClickBanner}>
      <div className={styles.banner}>
        <div className={styles.label1}>TextIn ParseX</div>
        <div className={styles.label2} style={isTestB ? { minWidth: 460 } : {}}>
          {labelText}
        </div>
        <div className={styles.btn}>
          <div className={styles.btnText}>立即查看</div>
          <img
            className={styles.arrow}
            src="/images/banner/icon_arrow_right.svg"
            width={20}
            alt=""
          />
        </div>
        <div className={styles.closeIconWrapper}>
          <img
            className={styles.closeIcon}
            src={'/images/banner/close_white.png'}
            width={14}
            height={14}
            alt=""
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
};

const TopBanner = () => {
  const router = useRouter();
  const pathname = router.asPath;
  const showBanner = !(
    pathsWithNoBanner.some((item) => pathname.startsWith(item)) ||
    pathname.includes('pdf_to_markdown')
  );
  if (!showBanner) {
    return null;
  }
  return <Banner />;
};

export default TopBanner;
